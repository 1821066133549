import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { ClassicSpinner } from 'react-spinners-kit'
import { useWeb3React } from '@web3-react/core'
import { NotificationManager, NotificationContainer } from 'react-notifications'

import MINTCONTRACT_ABI from '../../assets/abis/MINTCONTRACT_ABI.json'
import config, { FREE_MINT_LIMIT } from '../../config/config'

const ethers = require('ethers')

const Mint = () => {
  const [publicState, setPublicState] = useState(false)
  const { account } = useWeb3React()
  //const [mintPrice, setMintPrice] = useState(0)
  //const [whiteListCount, setWhiteListCount] = useState(0)
  const [totalMintedCount, setTotalMintedCount] = useState(0)
  const [mintCount, setMintCount] = useState(1)
  const [mintState, setMintState] = useState(false)
  const [dynamicPrice, setDynamicPrice] = useState(0)
  const [userMintCount, setUserMintCount] = useState(0)
  //const [userFreeMintCount, setUserFreeMintCount] = useState(0)

  const Provider = new ethers.providers.Web3Provider(window.ethereum)
  const Signer = Provider.getSigner()

  const NFTMintContract = new ethers.Contract(
    config.MINTCONTRACT_ADDRESS,
    MINTCONTRACT_ABI,
    Signer,
  )

  const controlMintCount = (state) => {
    if (state === true) {
      mintCount >= 1 ? setMintCount(1) : setMintCount(mintCount + 1)
    } else {
      mintCount <= 1 ? setMintCount(1) : setMintCount(mintCount - 1)
    }
  }

  useEffect(() => {
    if (totalMintedCount >= FREE_MINT_LIMIT) {
      setPublicState(true)
    } else {
      setPublicState(false)
    }
  }, [totalMintedCount]) // Reacts to changes in totalMintedCount

  const mintFunc = async () => {
    if (!account) {
      NotificationManager.info('Please Connect Wallet')
      return
    }

    setMintState(true)

    try {
      // Fetch the dynamic price per NFT
      const pricePerNFT = await NFTMintContract.calculateDynamicPrice()
      // Calculate total price for the desired mint count
      const totalPrice = pricePerNFT.mul(ethers.BigNumber.from(mintCount))

      const mintTx = await NFTMintContract.mint(mintCount, {
        value: totalPrice, // Send the total calculated price with the transaction
        gasLimit: config.totalGas * mintCount, // Ensure you have a sufficient gas limit
      })

      await mintTx.wait()
      NotificationManager.success('Minted successfully!')
    } catch (error) {
      console.error('Minting error:', error)
      NotificationManager.error('Minting failed. Please try again.')
    } finally {
      setMintState(false)
      // Refresh the data to reflect the new state
      totalSupply()
      getDynamicPrice()
      getUserMintCount()
    }
  }

  const totalSupply = async () => {
    let balance = 0
    if (account !== undefined) {
      balance = await NFTMintContract.totalSupply()
      const count = Number(balance.toString())
      setTotalMintedCount(count)
      if (count >= config.MaxMintCounts) {
      }
    }
  }

  //const isWhiteList = async () => {
  //  if (account) {
  //  try {
  //    let mintCountBigNumber = await NFTMintContract.getMintCount(account)
  //    let mintCount = mintCountBigNumber.toNumber() // Convert BigNumber to a standard number
  //    console.log(`Mint count for account ${account}:`, mintCount)
  //    setWhiteListCount(mintCount)
  //  } catch (error) {
  //     console.error('Error fetching mint count:', error)
  //   }
  //  } else {
  //   console.log('No account found.')
  // }
  // }

  const getDynamicPrice = async () => {
    const supply = await NFTMintContract.totalSupply()
    if (supply.toNumber() >= 2) {
      const price = await NFTMintContract.calculateDynamicPrice()
      setDynamicPrice(price.toString())
    } else {
      setDynamicPrice(0) // Free mint
    }
  }

  useEffect(() => {
    getDynamicPrice()
  }, [totalMintedCount, publicState, NFTMintContract, account]) // Adjust dependencies as necessary

  const getUserMintCount = async () => {
    const count = await NFTMintContract.freeMintCountPerAddress(account)
    setUserMintCount(count)
    console.log(count)
  }

  useEffect(() => {
    if (account) totalSupply()
    getUserMintCount()
  }, [account]) // Re-run when account changes// Add getDynamicPrice and getUserMintCount to the dependency array if they're defined outside useEffect

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.5 }}
    >
      <div className="container relative small">
        <h3
          className="fn__maintitle font-extrabold mt-32 my-10 text-3xl text-center"
          data-align="left"
        ></h3>

        <div className="desc"></div>
        <div className="metaverse_fn_mintbox">
          <div className="mint_title">
            {totalMintedCount < FREE_MINT_LIMIT ? (
              <span>Free Mint is Active</span>
            ) : (
              <span>Now it's Public Mint</span>
            )}
          </div>
          <div className="mint_body">
            <div className="mint_content">
              <div className="mint_list">
                <ul>
                  <li>
                    <div className="item">
                      <h4>FREE Left</h4>
                      <h3>
                        <span className="total_price font-bold text-xl text-white">
                          {' '}
                          {FREE_MINT_LIMIT - totalMintedCount}{' '}
                        </span>
                      </h3>
                    </div>
                  </li>
                  {publicState && (
                    <li>
                      <div className="item">
                        <h4>Quantity</h4>
                        <div className="flex gap-3 justify-center text-2xl w-full">
                          <span
                            className="cursor-pointer decrease"
                            onClick={() => controlMintCount(false)}
                          >
                            -
                          </span>
                          <span className="summ" data-price="2.25">
                            {mintCount}
                          </span>
                          <span
                            className="cursor-pointer increase"
                            onClick={() => controlMintCount(true)}
                          >
                            +
                          </span>
                        </div>
                      </div>
                    </li>
                  )}
                  <li>
                    <div className="item">
                      <h4>Minted</h4>
                      <h3>
                        <span className="total_price font-bold text-xl text-white">
                          {totalMintedCount}
                        </span>{' '}
                        <span className="total_price font-bold text-xl text-white">
                          / {config.MaxMintCounts}
                        </span>
                      </h3>
                    </div>
                  </li>
                  <li>
                    <div className="item">
                      <h4>Total Price</h4>
                      <h3>
                        <span className="total_price font-bold text-xl text-white">
                          {publicState
                            ? `${
                                dynamicPrice / 1000000000000000000
                              } FLR per mint`
                            : '1st 500 Free'}
                        </span>
                      </h3>
                    </div>
                  </li>
                </ul>
              </div>
              {totalMintedCount !== config.MaxMintCount && (
                <div className="mint_desc">
                  <button
                    className={`metaverse_fn_button ${
                      mintState || (!publicState && userMintCount >= 2)
                        ? 'disabled'
                        : ''
                    }`}
                    onClick={mintFunc}
                    disabled={mintState || (!publicState && userMintCount >= 2)}
                  >
                    {publicState
                      ? 'Mint Now'
                      : userMintCount >= 2
                      ? 'Free Limit Reached'
                      : 'Mint Now'}
                  </button>
                  {!publicState && userMintCount >= 2 && (
                    <h1 className="font-bold ml-5 mt-2 text-md text-white">
                      This address already minted 2 MOMOS
                    </h1>
                  )}
                  {publicState && (
                    <h1 className="font-bold ml-5 mt-2 text-md text-white">
                      Dynamic Price: {dynamicPrice / 1000000000000000000} FLR
                    </h1>
                  )}
                </div>
              )}
              {totalMintedCount === config.MaxMintCounts && (
                <div className="mint_desc">
                  <h1 className="font-bold ml-5 mt-2 text-md text-white">
                    WE ARE SOLD OUT
                  </h1>
                </div>
              )}{' '}
            </div>

            <div className="mint_checked">{publicState && <p></p>}</div>
          </div>
        </div>
        {mintState && (
          <div className="metaverse_fn_preloader opacity-90 rounded-2xl">
            <ClassicSpinner size={40} />
            <h1 className="font-bold ml-5 mt-2 text-md text-white">
              {' '}
              Fomo Momos ...
            </h1>
          </div>
        )}
      </div>
      <NotificationContainer />
    </motion.section>
  )
}

export default Mint
